.App {
  /* text-align: center; */
}

.App-logo {
  width: 100%;
}


.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animation-title {
  position: relative;
  animation-name: example;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@keyframes example {
  0% {
    color: black;
    left: 200px;
    top: 0px;
  }

  100% {  
    color: black;
    left: 80%;
    top: 0px;
  }

}

.fb-icon {
  height: 100px;
  width: 100px;
  padding: 16px;
}

ul > li {
  font-weight: bold;
}

ol>li {
  font-weight: bold;
}
